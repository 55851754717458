import React from "react"
import ProductHighlight from "../Core/ProductHighlight"
import CtaColumnsBlock from "./CtaColumnsBlock"

const PageContents = (props) => {
  const { blocks } = props
  let pageContents = []

  blocks.forEach((block, index) => {
    const { __typename: type } = block

    if (type === "ContentfulProductHighlightBlock") {
      pageContents.push(<ProductHighlight {...block} key={index} />)
    }
    if (type === "ContentfulCtaColumnsBlock") {
      pageContents.push(<CtaColumnsBlock {...block} key={index} />)
    }
  })

  return <React.Fragment>{pageContents}</React.Fragment>
}

export default PageContents
