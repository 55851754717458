import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

const StyledImage = styled(GatsbyImage)`
  border-radius: 15px;
  overflow: hidden;
  min-height: 195px;
  /* max-height: 459px; */
`

const ImageBlock = (props) => {
  const { image, altText } = props
  return (
    <StyledImage
      image={image.gatsbyImageData}
      alt={altText}
      imgStyle={{ objectFit: "cover" }}
    />
  )
}

export default ImageBlock
