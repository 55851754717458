import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Instagram from "../../../resources/img/ui/social/instagram.svg"
import Facebook from "../../../resources/img/ui/social/facebook.svg"
import Twitter from "../../../resources/img/ui/social/twitter.svg"

const ClosingBlockContainer = styled.div`
  border-top: 1px solid ${colors.grey};
  padding-top: 64px;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Title = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 12px;
  line-height: 2;
  letter-spacing: 2.4px;
  text-transform: uppercase;
`

const About = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 1.6;
  margin-top: 28px;
`

const SocialLinks = styled.div`
  margin-top: 38px;
  display: flex;
  align-items:center;
`

const Social = styled.a`
  margin-right: 24px;
  img {
    max-width: ${(props) =>
      props.instagram ? "14px" : props.facebook ? "8px" : "16px"};
  }
`

const ClosingBlock = (props) => {
  const { author } = props
  const { about, socialLinks } = author

  const renderSocialLinks = (social) => {
    if (social.includes("instagram")) {
      return (
        <Social
          instagram={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="Instagram" />
        </Social>
      )
    }
    if (social.includes("facebook")) {
      return (
        <Social
          facebook={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} alt="Facebook" />
        </Social>
      )
    }
    if (social.includes("twitter")) {
      return (
        <Social
          twitter={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </Social>
      )
    }
  }

  return (
    <ClosingBlockContainer>
      <Title>About Author</Title>
      <About> {renderRichText(about)}</About>
      {socialLinks && socialLinks.length > 0 && (
        <SocialLinks>
          {socialLinks.map((social, index) => {
            return (
              <React.Fragment key={index}>
                {renderSocialLinks(social)}
              </React.Fragment>
            )
          })}
        </SocialLinks>
      )}
    </ClosingBlockContainer>
  )
}

export default ClosingBlock
