import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Instagram from "../../resources/img/ui/social/instagram.svg"
import Facebook from "../../resources/img/ui/social/facebook.svg"
import Twitter from "../../resources/img/ui/social/twitter.svg"

const StickyBarContainer = styled.div`
  max-width: 467px;
  width: 100%;
  margin-right: 58px;
  position: sticky;
  top: 120px;
  height: max-content;
  border: 1px solid ${colors.grey};
  padding: 33px 36px 72px;
  background: ${colors.peach};
  min-width: 260px;
  flex-wrap: wrap;
  display: none;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
  }
`

const Section = styled.div`
  min-width: 100%;
  margin-bottom: 64px;
  :last-of-type {
    margin-bottom: 0;
  }
`

const Copy = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 1.6;
  color: ${colors.grey};
`

const SectionTitle = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  line-height: normal;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  margin-bottom: 14px;
`

const Tag = styled(Copy)`
  display: inline-block;
  margin-right: 2px;
  word-break: break-word;
`

const Social = styled.a`
  margin-right: 24px;
  img {
    max-width: ${(props) =>
      props.instagram ? "14px" : props.facebook ? "8px" : "16px"};
  }
`

const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`

const ShareLink = styled.a`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 1.6;
  color: ${colors.grey};
  text-decoration: none;
  transition: all 0.4s ease-in;

  :hover {
    span {
      color: ${colors.clay};
    }
  }

`

const StickyBar = (props) => {
  const { tags, author, summary, title, slug } = props
  const articleUrl = `${process.env.WEBSITE_URL}/article/${slug}`

  const shares = [
    {
      title: "FB",
      link: `https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`,
    },
    {
      title: "Email",
      link: `mailto:?subject=${title}&body=${articleUrl}`,
    },
    {
      title: "TW",
      link: `http://twitter.com/share?text=${title}&url=${articleUrl}`,
    },
  ]

  const renderSocialLinks = (social) => {
    if (social.includes("instagram")) {
      return (
        <Social
          instagram={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="Instagram" />
        </Social>
      )
    }
    if (social.includes("facebook")) {
      return (
        <Social
          facebook={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} alt="Facebook" />
        </Social>
      )
    }
    if (social.includes("twitter")) {
      return (
        <Social
          twitter={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </Social>
      )
    }
  }

  return (
    <StickyBarContainer>
      {tags && tags.length > 0 && (
        <Section>
          <SectionTitle>Tags:</SectionTitle>
          {tags.map((tag, index) => {
            const isLast = index === tags.length - 1
            return (
              <Tag key={index}>
                {tag}
                {!isLast && ","}
              </Tag>
            )
          })}
        </Section>
      )}
      {author && (
        <Section>
          <SectionTitle>About {author.name}</SectionTitle>
          <Copy>{author.shortAbout}</Copy>
          {author.socialLinks && author.socialLinks.length > 0 && (
            <SocialLinks>
              {author.socialLinks.map((social, index) => {
                return (
                  <React.Fragment key={index}>
                    {renderSocialLinks(social)}
                  </React.Fragment>
                )
              })}
            </SocialLinks>
          )}
        </Section>
      )}
      {summary && (
        <Section>
          <SectionTitle>Summary:</SectionTitle>
          <Copy>{renderRichText(summary)}</Copy>
        </Section>
      )}
      <Section>
        <SectionTitle>Share</SectionTitle>
        {shares.map((share, index) => {
          const isLast = index === shares.length - 1;
          return <ShareLink href={share.link} target="_blank" key={index}><span>{share.title} </span>{!isLast && "/ "}</ShareLink>
        })}
      </Section>
    </StickyBarContainer>
  )
}

export default StickyBar
