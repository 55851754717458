import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import QuoteBlock from "./body/QuoteBlock"
import QnABlock from "./body/QnABlock"
import TextBlock from "./body/TextBlock"
import MediaBlock from "./body/MediaBlock"
import MultiMediaBlock from "./body/MultiMediaBlock"
import ClosingBlock from "./body/ClosingBlock"

const BodyContainer = styled.div`
  margin-top: 55px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 85px;
  }
`

const Body = (props) => {
  const { body, author } = props
  const bodyContents = []

  body.forEach((section, index) => {
    const { __typename: type } = section

    if (type === "ContentfulQuestionAnswer") {
      bodyContents.push(<QnABlock {...section} key={index} />)
    }

    if (type === "ContentfulMultipleMediaBlock") {
      bodyContents.push(<MultiMediaBlock {...section} key={index} />)
    }

    if (type === "ContentfulImageBlock") {
      bodyContents.push(<MediaBlock {...section} key={index} />)
    }

    if (type === "ContentfulQuoteBlock") {
      bodyContents.push(<QuoteBlock {...section} key={index} />)
    }

    if (type === "ContentfulTextBlock") {
      bodyContents.push(<TextBlock {...section} key={index} />)
    }
  })

  return (
    <BodyContainer>
      {bodyContents}
      {author && <ClosingBlock author={author} />}
    </BodyContainer>
  )
}

export default Body
