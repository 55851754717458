import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import ImageBlock from "./media/ImageBlock"
import VideoBlock from "./media/VideoBlock"

const MediaBlockContainer = styled.div`
  margin-bottom: 44px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 84px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-gap: 29px;
  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`

const MultiMediaBlock = (props) => {
  const { medias } = props

  return (
    <MediaBlockContainer>
      <Grid>
        {medias.map((media, index) => {
          const { image: file } = media
          const isVideo = file.contentType === "video/mp4"
          return (
            <React.Fragment key={index}>
              {isVideo ? <VideoBlock {...media} /> : <ImageBlock {...media} />}
            </React.Fragment>
          )
        })}
      </Grid>
    </MediaBlockContainer>
  )
}

export default MultiMediaBlock
