import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import fonts from "../../../../styles/fonts"
import colors from "../../../../styles/colors"
import breakpoints from "../../../../styles/breakpoints"

const VideoContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  position: relative;
`

const Video = styled.div`
  position: relative;
  max-height: 416px;
  min-height: 195px;
  height: 56.25vw;
  width: 100%;
  border-radius: 15px;

  video {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    height: 33.33vw;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 56.25vw;
  }
`

const PlayButton = styled.div`
  position: absolute;
  border-radius: 50%;
  background: ${(props) => (props.hover ? colors.grey : colors.clay)};
  z-index: 1;
  bottom: 17px;
  right: 17px;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${fonts.canelaThinItalic};
  color: ${colors.white};
  font-size: 24px;
  line-height: 1.2;
  transition: all 0.4s ease;
  cursor: pointer;

  @media (min-width: ${breakpoints.md}) {
    bottom: 31px;
    right: 35px;
    width: 58px;
    height: 58px;
  }
`

const VideoBlock = (props) => {
  const { image } = props
  const [play, setPlay] = useState(false)
  const [hover, setHover] = useState(false)
  let videoRef = useRef(null)

  useEffect(() => {
    if (videoRef) {
      if (play) {
        videoRef.play()
      } else {
        videoRef.pause()
      }
    }
  }, [play])

  return (
    <VideoContainer>
      <Video>
        <video
          src={image.file.url}
          ref={(el) => (videoRef = el)}
          muted
          loop
          playsInline
        />
      </Video>
      <PlayButton
        hover={hover ? 1 : 0}
        onClick={() => setPlay(!play)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        {play ? "Stop" : "Play"}
      </PlayButton>
    </VideoContainer>
  )
}

export default VideoBlock
