import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import Copy from "./Copy"

const QnABlockContainer = styled.div`
  margin-bottom: 44px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 84px;
  }
`

const Text = styled.div`
  ${fonts.labGrotesqueBold};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 53px;

  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 49px;
  }

`

const Mark = styled.div`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 1;
  margin-bottom: 30px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 19px;
  }
`

const QnABlock = (props) => {
  const { question, answer } = props
  
  return (
    <QnABlockContainer>
      <Text question={1}>
        <Mark>Q.</Mark>
        {renderRichText(question)}
      </Text>
      <div>
        <Mark>A.</Mark>
        <Copy text={answer} />
      </div>
    </QnABlockContainer>
  )
}

export default QnABlock
