import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"

const QuoteContainer = styled.div`
  margin-bottom: 44px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 84px;
  }
`

const Quote = styled.div`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 1.2;
  color: ${colors.grey};
  br {
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    font-size: 50px;
    line-height: 1.2;
    br {
      display: block;
    }
  }
`

const QuoteBlock = (props) => {
  const { quote } = props
  return (
    <QuoteContainer>
      <Quote>
        "<br />
        {quote}
      </Quote>
    </QuoteContainer>
  )
}

export default QuoteBlock
