import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const IntroContainer = styled.div`
  margin-top: 53px;
`

const Category = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  line-height: normal;
  letter-spacing: 3.8px;
  text-transform: uppercase;

  span {
    border-bottom: 2px solid ${props => props.color}; 
  }
`

const TitleContainer = styled.div`
  margin-top: 26px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 40px;
  }
`

const Title = styled.h1`
  ${fonts.canelaThin};
  font-size: 40px;
  line-height: 1.2;

`

const IntroCopy = styled.div`
  ${fonts.labGrotesqueLight};
  margin-top: 33px;
  font-size: 18px;
  line-height: 1.8;
  color: ${colors.grey};
  a {
    color: inherit;
  }
  p {
    margin-bottom: 32px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 11px;
  }
`

const Date = styled.div`
  ${fonts.labGrotesqueLight};
  margin-top: 33px;
  font-size: 18px;
  line-height: 1.8;

  b {
    ${fonts.labGrotesqueBold};
  }

  i {
    font-style: italic;
  }
`

const Intro = (props) => {
  const { category, date, estimatedReadingTime, intro, title } = props
  const { title: categoryTitle, categoryColor } = category

  return (
    <IntroContainer>
      <Category color={categoryColor}>
        <span>{categoryTitle}</span>
      </Category>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <IntroCopy>{renderRichText(intro)}</IntroCopy>
      <Date> <b>{estimatedReadingTime} min read </b>| <i>{date}</i></Date>
    </IntroContainer>
  )
}

export default Intro
