import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import ContentContainer from "../Core/Container/ContentContainer"
import ButtonGrey from "../Core/Buttons/ButtonGrey"
import { Col, Row } from "styled-bootstrap-grid"
import ArticleList from "./ArticleList"

const ColumnsBlockContainer = styled.div`
  margin: 76px 0;

  @media (min-width: ${breakpoints.md}) {
    margin: 246px 0 108px;
  }
`

const IntroSection = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    max-width: 739px;
    margin: 0 auto 51px;
  }
`

const IntroTitle = styled.h2`
  ${fonts.canelaThin};
  color: ${colors.grey};
  font-size: 40px;
  line-height: 100%;

  @media (min-width: ${breakpoints.md}) {
    font-size: 72px;
  }
`

const Intro = styled.div`
  ${fonts.labGrotesqueLight};
  color: ${colors.grey};
  font-size: 18px;
  line-height: 160%;
  margin-top: 13px;

  p {
    margin-bottom: 18px;
    b {
      ${fonts.labGrotesqueBold};
    }
    i {
      font-style: italic;
    }
    a {
      color: ${colors.grey};
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }
`

const ReadAll = styled.div`
  width: 100%;
  margin-top: 49px;

  a {
    width: min-content;
    min-width: 317px;
    margin: 0 auto;
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 96px;
    a {
      min-width: 249px;
    }
  }
`

const ListContainer = styled.div`
  margin-top: 76px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }

`

const CtaColumnsBlock = (props) => {
  const { header, subHeader, ctaLabel, ctaLink, columns } = props

  return (
    <ColumnsBlockContainer>
      <Row>
        <Col>
          <ContentContainer>
            <IntroSection>
              <IntroTitle>{header}</IntroTitle>
              {subHeader && (
                <Intro>{renderRichText(subHeader)}</Intro>
              )}
            </IntroSection>
          </ContentContainer>
        </Col>
        {ctaLabel && (
          <Col mdOrder="last">
            <ReadAll>
              <ContentContainer>
                <ButtonGrey to={ctaLink}>{ctaLabel}</ButtonGrey>
              </ContentContainer>
            </ReadAll>
          </Col>
        )}
        <Col>
        <ListContainer>
          <ArticleList articles={columns} />
        </ListContainer>
        </Col>
      </Row>
    </ColumnsBlockContainer>
  )
}

export default CtaColumnsBlock
