import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql, Link } from "gatsby"
import Intro from "../components/Article/Intro"
import Body from "../components/Article/Body"
import PageContents from "../components/Article/PageContents"
import ContentContainer from "../components/Core/Container/ContentContainer"
import DetailBar from "../components/Article/DetailBar"
import StickyBar from "../components/Article/StickyBar"
import styled from "styled-components"
import breakpoints from "../styles/breakpoints"
import ShopIcon from "../resources/img/article/shop-button.svg"

const ArticleContent = styled.div`
  @media (min-width: ${breakpoints.md}) {
    max-width: 743px;
  }
`

const FlexBox = styled.div`
  margin-bottom: 126px;
  margin-top: 29px;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    margin-bottom: 168px;
    margin-top: 0;
  }
`

const ShopButtonContainer = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  display: none;
  z-index: 10;

  @media (min-width: ${breakpoints.md}) {
    display: block;
  }
`

const Shop = styled(Link)`
  position: sticky;
  right: 0;
  top: 120px;
  display: flex;

  img {
     max-width: 52px;
  }

  @media (min-width: ${breakpoints.xl}) {
    img {
      max-width: 72px;
    }
  }

  @media (min-width: ${breakpoints.xxl}) {
    img {
      max-width: 105px;
    }
  }
`

const ArticleContainer = styled.div`
  height: 100%;
  position: relative;
`

const ArticleTemplate = (props) => {
  const { data, location } = props
  const path = location.pathname
  const { page } = data
  const { body, metaTitle, metaDescription, author, blocks } = page

  return (
    <Layout>
      <Seo title={metaTitle} description={metaDescription} path={path} />
      <ArticleContainer>
        <ShopButtonContainer>
          <Shop to="/product/cycle-soother-patch">
            <img src={ShopIcon} alt="Shop" />
          </Shop>
        </ShopButtonContainer>
        <ContentContainer>
          <FlexBox>
            <DetailBar {...page} />
            <StickyBar {...page} />
            <ArticleContent>
              <Intro {...page} />
              <Body body={body} author={author} />
            </ArticleContent>
          </FlexBox>
        </ContentContainer>
        {blocks && <PageContents blocks={blocks} />}
      </ArticleContainer>
    </Layout>
  )
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleQuery($slug:String!) {
    page: contentfulArticle(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      summary {
        raw
      }
      tags
      title
      slug
      date(formatString: "MMMM DD, YYYY")
      category {
        title
        categoryColor
      }
      estimatedReadingTime
      intro {
        raw
      }
      author {
        about {
          raw
        }
        name
        shortAbout
        socialLinks
      }
      ...articleBodyFragment
      blocks {
        __typename
        ... on ContentfulProductHighlightBlock {
          id
          ctaLabel
          introContent {
            raw
          }
          product {
            productSlug
          }
        }
        ... on ContentfulCtaColumnsBlock {
          id
          header
          subHeader {
            raw
          }
          columns {
            ... on ContentfulArticle {
              category {
                title
                categoryColor
              }
              slug
              title
              thumbnail {
                gatsbyImageData(width: 636, aspectRatio: 1)
              }
            }
          }
          ctaLabel
          ctaLink
        }
      }
    }
  }
`
