import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import ImageBlock from "./media/ImageBlock"
import VideoBlock from "./media/VideoBlock"

const MediaBlockContainer = styled.div`
  margin-bottom: 44px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 84px;
  }
`

const MediaBlock = (props) => {
  const { image: { file } } = props;
  const isVideo = file.contentType === "video/mp4"
  return (
    <MediaBlockContainer>
      {isVideo ? <VideoBlock {...props} /> : <ImageBlock {...props} />}
    </MediaBlockContainer>
  )
}

export default MediaBlock
