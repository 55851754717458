import React from "react"
import styled from "styled-components"
import breakpoints from "../../../styles/breakpoints"
import Copy from "./Copy"

const TextBlockContainer = styled.div`
  margin-bottom: 44px;
  @media (min-width: ${breakpoints.md}) {
    margin-bottom: 84px;
  }
`

const TextBlock = (props) => {
  const { content } = props
  return (
    <TextBlockContainer>
      <Copy text={content}/>
    </TextBlockContainer>
  )
}

export default TextBlock
