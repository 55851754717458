import React from "react"
import styled from "styled-components"
import fonts from "../../../styles/fonts"
import colors from "../../../styles/colors"
import breakpoints from "../../../styles/breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

const StyledCopy = styled.div`
  font-size: 18px;
  line-height: 1.8;
  color: ${colors.grey};

  h2 {
    ${fonts.labGrotesqueBold};
  }

  p {
    margin-bottom: 32px;
    b {
      ${fonts.labGrotesqueBold};
    }

    i {
      font-style: italic;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    background: ${colors.peachMedium};
  }

  ul {
    li {
      list-style: disc;
      margin-left: 20px;
    }
  }

  ol {
    li {
      margin-bottom: 32px;
      :last-of-type {
        margin-bottom: 0;
      }
      div {
        display: inline-block;
        position: relative;
        padding: 0 6px;
        height: auto;
        ::after {
          content: "";
          width: 100%;
          height: 13px;
          background: ${colors.peachMedium};
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          z-index: -1;
        }
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    ol {
      li {
        display: flex;
        div {
          height: min-content;
          margin-right: 44px;
          ::after {
            top: 50%;
            transform: translateY(0);
            left: -10%;
            z-index: -1;
          }
        }
      }
    }
  }
`

const OrderedList = (props) => {
  const { listItems } = props
  return (
    <ol>
      {listItems.map(({ props }, index) => {
        return (
          <li key={index}>
            <div>{index + 1}.</div>
            <br />
            {props.children}
          </li>
        )
      })}
    </ol>
  )
}


const options = {
  renderNode: {
    [BLOCKS.OL_LIST]: (node, children) => (
      <OrderedList listItems={children} ol />
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      )
    },
  },
}

const Copy = (props) => {
  const { text } = props
  return (
    <StyledCopy>{renderRichText(text, options)}</StyledCopy>
  )
}

export default Copy
