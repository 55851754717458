import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ArrowDown from "../../resources/img/ui/dark/chevron-down-dark.svg"
import Instagram from "../../resources/img/ui/social/instagram.svg"
import Facebook from "../../resources/img/ui/social/facebook.svg"
import Twitter from "../../resources/img/ui/social/twitter.svg"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const DetailBarContainer = styled.div`
  display: flex;
  position: relative;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const Section = styled.div`
  background: ${colors.peach};
  width: 50%;
  border: 1px solid ${colors.grey};
  border-left: ${(props) => (props.last ? "none" : `1px solid ${colors.grey}`)};
  padding: 17px 15px 17px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  text-transform: uppercase;
  letter-spacing: 3.8px;
  cursor: pointer;
  border-bottom-color: ${(props) => props.isDisplayed && "transparent"};

  img {
    transform: ${(props) =>
      props.isDisplayed ? "rotate(180deg)" : "rotate(0)"};
    transition: all 0.4s ease;
  }
`

const ShareOverlay = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid ${colors.grey};
  border-top: none;
  background: ${colors.peach};
  padding: 41px 40px 45px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const ShareButton = styled.div`
  min-width: 100%;
  text-align: center;
  margin-bottom: 18px;
  color: ${colors.grey};
  transition: all 0.4s ease-in;
  a {
    color: inherit;
    text-decoration: none;
  }
  :last-of-type {
    margin-bottom: 0;
  }

  :hover {
    color: ${colors.clay};
  }
`

const Dropdowns = styled.div`
  position: absolute;
  top: 100%;
  width: 100%;
  border: 1px solid ${colors.grey};
  border-top: none;
  background: ${colors.peach};
  z-index: 1;
`

const Dropdown = styled.div`
  min-width: 100%;
  border-bottom: 1px solid ${colors.grey};
  text-align: center;
  :last-of-type {
    border-bottom: none;
  }
`

const Drawer = styled.div`
  max-height: ${props => props.visible? "100vh" : "0"};
  overflow: hidden;
  transition: all 0.4s ease;
`

const DropdownContent = styled.div`
  padding: 5px 20px 46px;
`

const DropdownTitle = styled.div`
  ${fonts.labGrotesqueBold};
  font-size: 19px;
  line-height: normal;
  letter-spacing: 3.8px;
  text-transform: uppercase;
  padding: 26px 20px;
  cursor: pointer;
`

const Author = styled(DropdownTitle)`
`

const Copy = styled.div`
  ${fonts.labGrotesqueLight};
  font-size: 18px;
  line-height: 1.6;
  color: ${colors.grey};
`

const Social = styled.a`
  margin-right: 24px;
  img {
    max-width: ${(props) =>
      props.instagram ? "14px" : props.facebook ? "8px" : "16px"};
  }
  :last-of-type {
    margin-right: 0;
  }
`

const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DetailBar = (props) => {
  const { tags, author, summary, title, slug } = props
  const [openTab, setOpenTab] = useState(null)
  const [ activeDropdown, setActiveDropdown ] = useState(null)

  const articleUrl = `${process.env.WEBSITE_URL}/article/${slug}`

  const shares = [
    {
      title: "Facebook",
      link: `https://www.facebook.com/sharer/sharer.php?u=${articleUrl}`,
    },
    {
      title: "Email",
      link: `mailto:?subject=${title}&body=${articleUrl}`,
    },
    {
      title: "Twitter",
      link: `http://twitter.com/share?text=${title}&url=${articleUrl}`,
    },
  ]

  const handleSectionChange = (type) => {
    if (openTab === type) {
      setOpenTab(null)
    } else {
      setOpenTab(type)
    }

    if (type === "share") {
      setActiveDropdown(null)
    }
  }

  const handleDropdownChange = (type) => {
    if (activeDropdown === type) {
      setActiveDropdown(null)
    } else {
      setActiveDropdown(type)
    }
  }

  const renderSocialLinks = (social) => {
    if (social.includes("instagram")) {
      return (
        <Social
          instagram={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Instagram} alt="Instagram" />
        </Social>
      )
    }
    if (social.includes("facebook")) {
      return (
        <Social
          facebook={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Facebook} alt="Facebook" />
        </Social>
      )
    }
    if (social.includes("twitter")) {
      return (
        <Social
          twitter={1}
          href={social}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={Twitter} alt="Twitter" />
        </Social>
      )
    }
  }

  return (
    <DetailBarContainer>
      <Section
        onClick={() => handleSectionChange("share")}
        isDisplayed={openTab === "share" ? 1 : 0}
      >
        Share <img src={ArrowDown} alt="Arrow" />
      </Section>
      <Section
        onClick={() => handleSectionChange("info")}
        isDisplayed={openTab === "info" ? 1 : 0}
        last
      >
        Info <img src={ArrowDown} alt="Arrow" />
      </Section>
      {openTab && openTab === "share" && (
        <ShareOverlay>
          {shares.map((social, index) => {
            return (
              <ShareButton key={index} target={"_blank"}>
                <a href={social.link}>{social.title}</a>
              </ShareButton>
            )
          })}
        </ShareOverlay>
      )}
      {openTab && openTab === "info" && (
        <Dropdowns>
          {tags && tags.length > 0 && (
            <Dropdown>
              <DropdownTitle onClick={() => handleDropdownChange("tags")}>Tags</DropdownTitle>
              <Drawer visible={activeDropdown === "tags"  ? 1 : 0}>
                <DropdownContent>
                  {tags.map((tag, index) => {
                    return <Copy key={index}>{tag}</Copy>
                  })}

                </DropdownContent>
              </Drawer>
            </Dropdown>
          )}
          {author && (
            <Dropdown>
              <DropdownTitle onClick={() => handleDropdownChange("author")}>Author</DropdownTitle>
              <Drawer visible={activeDropdown === "author"  ? 1 : 0}>
                <DropdownContent>
                  <Author>About {author.name}</Author>
                  <Copy>{author.shortAbout}</Copy>
                  {author.socialLinks && author.socialLinks.length > 0 && (
                    <SocialLinks>
                      {author.socialLinks.map((social, index) => {
                        return (
                          <React.Fragment key={index}>
                            {renderSocialLinks(social)}
                          </React.Fragment>
                        )
                      })}
                    </SocialLinks>
                  )}
                </DropdownContent>
              </Drawer>
            </Dropdown>
          )}
          {summary && (
            <Dropdown>
              <DropdownTitle onClick={() => handleDropdownChange("summary")}>Summary</DropdownTitle>
              <Drawer visible={activeDropdown === "summary"  ? 1 : 0}>
                <DropdownContent>
                  <Copy>{renderRichText(summary)}</Copy>
                </DropdownContent>
              </Drawer>
            </Dropdown>
          )}
        </Dropdowns>
      )}
    </DetailBarContainer>
  )
}

export default DetailBar
